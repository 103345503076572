/* You can add global styles to this file, and also import other style files */
@import 'abstracts/variables';
@import 'abstracts/mixins';

@import '~material-design-icons/iconfont/material-icons.css';

.dark-theme {
  .th-white-text {
    mfdefaultsorter {
      a {
        color: #cccccc;
      }
    }
  }

  .mat-table > thead {
    background-color: #1a1a1a !important;
  }

  .dataTables_length {
    color : white !important
  }
  
  .dataTables_length > label > select > option {
    background-color: $gray-300 !important;
  }
  
  .dataTables_length > label > select {
    color: white !important;
  }
  
  .dataTables_filter {
    color : white !important
  }
  
  input[type=search]{
    color : white !important
  }
  
  .dataTables_info {
    color : white !important
  }

  .dropzone {
    background-color: #424242 !important;
  }
  .textLink {
      background-color: black;
      color: $white;
  }
}


.detail-table {
  margin-top: -15px !important;
  overflow-x: auto;
  display: block;

  thead {
    background-color: #1a1a1a;
    display: block;

    tr {
      th {
        background-color: #1a1a1a;
        font-weight: normal;
        font-size: 11px;

        mfdefaultsorter {
          a {
            color: #cccccc;
          }
        }
      }
    }
  }

  tbody {
    overflow-y: scroll;
    display: block;
    height: 100px;
    padding-left: 10px;
    background-color: #111111;
    @include scrollbars(10px, lightgrey, #555555);
  }
}

.claim-list-table {
  overflow-x: auto;
}

.mat-sort-header-content {
  width: 100%;
}

.sideNavHeight100 {
  height: 96% !important;
}

.dialogContent {
  background-color: #111111;
}

.claim-add-dialogs .mat-dialog-container {
  border: 5px solid $gray-500;
}

mat-checkbox.label-top {
  .mat-checkbox-layout {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;

    .mat-checkbox-label {
      color: rgba(255, 255, 255, 0.7);
      font-size: 0.8rem;
      text-align: center;
    }

    .mat-checkbox-inner-container {
      margin: 0;
    }
  }
}

.noPadding {
  padding: 0 0 !important; }



tr.mat-header-row {
    height: 35px !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #d5ad4d !important;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #d5ad4d !important;
}



.mat-header-cell{
  font-size: 1em !important;
}

.mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important;
  }

// Light theme related css

.light-theme {
  background-color: $white;

  header:not(.header), .sidebar-header {
    background-color: $gray-400 !important;
  }

  .search-filter {
    color: $gray-000;
  }

  .header-field-outer {
    background-color: $gray-lighter !important;
    color: $gray-000 !important;
  }

  table thead th, table thead td {
    background-color: $gray-400 !important;
    border-bottom: none !important;
    color: $gray-900 !important;
  }

  table tbody tr, table tbody td {
    background-color: $gray-lighter;
    color: $gray-000 !important;
  }

  .dataTables_length {
    color: $gray-000 !important
  }

  .dataTables_length > label > select > option {
    background-color: $gray-000 !important;
  }

  .dataTables_length > label > select {
    color: $gray-000 !important;
  }

  .dataTables_filter, .sidebar-body {
    color: $gray-000 !important;
  }


  legend {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  .mat-paginator-container {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  fieldset {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  .data-table-dark + fieldset {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  .data-table-dark + fieldset legend {
    background-color: $gray-850 !important;
    color: $dark !important;
  }

  .form-group-static input {
    background-color: $gray-850 !important;
    color: $dark !important;
    border-bottom: 1px solid $dark;
  }

  .form-group-static mat-select {
    background-color: $gray-850 !important;
    color: $dark !important;
    border-bottom: 1px solid $dark;
  }

  .mat-input-element {
    color: $dark !important;
  }

  input[type=search] {
    color: $dark !important;
  }

  button.custom-button, button.custom-button-green, button.custom-button-red, button.mat-button, .header-field-outer, .header-button-outer, .btn-dark, .sidebar-actions .btn {
    background-color: $gray-850 !important;
    color: $dark !important;
    border-color: $gray-850 !important;
  }

  div.visual-indicator {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  div.sub-menu button {
    background-color: $gray-400 !important;
    color: $gray-900 !important;
  }

  .mat-dialog-container .container-fluid, .mat-dialog-container .container-sm, .mat-dialog-container .container-md, .mat-dialog-container .container-lg, .mat-dialog-container .container-xl {
    background-color: $gray-lighter !important;
    color: $dark;
  }

  .mat-checkbox-label {
    color: inherit !important;
  }

  .mat-autocomplete-panel.mat-autocomplete-visible, .ng-sidebar {
    background-color: lightgray;
  }

  .mat-form-field-appearance-legacy .mat-form-field-label {
    color: $gray-000;
  }

  .dealer-search-input {
    color: $dark !important;
  }

  .mat-button-toggle-checked {
    background-color: $gray-850 !important;
  }

  .change-title {
    background-color: $gray-400 !important;
    border-bottom: none !important;
    color: $gray-900 !important;
  }

  label, .form-field-label {
    color: $dark !important;
  }

  .recent button {
    color: $dark !important;
  }

  .listBackground ::-webkit-scrollbar-track {
    background: $white;
  }

  .listContainer {
    background-color: $white;
    color: $dark;
  }

  .listBackground div {
    background-color: $white;
  }

  .listContainer .listItem:hover {
    color: $white !important;

    button {
      color: $white !important;
    }

    button:hover {
      color: #8c7133 !important;
    }
  }

  .searchbar input {
    background-color: $gray-850 !important;
    color: $dark !important;
  }

  .date-range {
    background-color: $gray-lighter !important;
  }

  .history {
    background-color: $gray-lighter !important;
    color: $dark !important;
  }

  .notification-recipients {
    background-color: $gray-850 !important;
    color: $dark !important;
  }

  .accordion-header, .accordion-header-coverage-group {
    color: $dark !important;
  }

  input {
    color: $dark !important;
  }

  .custom-button:hover {
    background-color: #b18f41 !important;
    border-color: #b18f41 !important;
    color: $dark !important;
  }

  .download-csv-link {
    color: $dark;
  }

  .app-version {
    color: $dark;
  }

  .dataTables_info {
    color: $dark !important;
  }

  .appVersion {
    color: $dark !important;
  }

  .dropzone {
    background-color: white;
  }

  .textLink {
    background-color: $gray-850;
    color: black;
  }
}


// Light theme related css end

.no-data-available {
  text-align: center;
}

/*
   src/styles.css (i.e. your global style)
*/
  .dataTables_empty {
    display: none;
  }

  .td-loading-spinner {
    height: 40px !important;
    align-items: center;
  }


  .appVersion {
    text-align: center;
  }

  .mat-table .mat-checkbox {
    cursor: pointer !important;
  }
