/*@import 'sass/_config';*/

// Import Theme
@import "themes/theme";
// Import Overrides
@import "themes/custom";

.mat-form-field {
  margin: 0.5rem 0;
}
