/*	=========================================================================
	Custom
	========================================================================= */

@import 'abstracts/mixins';

/*  Overrides
    ========================================================================= */

// reduce the size of the mat-flat-button when its used as a matsuffix
[mat-flat-button][matsuffix]{
  line-height: 1.6rem;
  min-width: 0;
}

// resolve issue with ladda in production
.mat-button.ladda-button, .mat-flat-button.ladda-button, .mat-icon-button.ladda-button {
  border: none !important;
  padding: 0 16px !important;
}

// resolve issue with ladda in production
.mat-stroked-button.ladda-button {
  padding: 0 15px !important;
}

// change the font size the body text size
.mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab {
  font-size: 1rem;
  text-transform: uppercase;
}

/*.mat-expansion-panel {
    background: $gray-900;
    color: $input-color;
}*/

.mat-form-field-appearance-legacy .mat-form-field-subscript-wrapper {
  margin-top: 0.2rem;
}

// change disabled text input dashed border to solid
.mat-form-field-appearance-legacy.mat-form-field-disabled .mat-form-field-underline {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0.7) 100%, transparent 0%)
}

// set the input color to whiteS
.mat-input-element {
  color: white !important;
}

// change disabled text input text color to default text color
/*.mat-input-element:disabled {
  color: $input-plaintext-color;
}*/

/*.mat-step-icon {
  mat-icon {
    display: none;
  }
}*/

/*  Enhancements
    ========================================================================= */

// material design enhancements
/*.mat-button-100 {
  width: 100%;
}*/

.checkbox-cell {
  .mat-checkbox-inner-container {
    height: 1.15384rem !important;
    width: 1.15384rem !important;
  }

  .mat-checkbox-label {
    line-height: 1;
  }

  &.no-label {
    .mat-checkbox-inner-container {
      margin: -0.15384rem 0 0 0 !important;
    }
  }
}

mat-accordion.mat-expansion-panel-field {
  position: absolute;
  top: -0.1rem;
  z-index: 1;

  width: 100%;

  /*.mat-expansion-panel {
    background-color: $gray-900;

    .mat-expansion-panel-header {
      padding: 0.37rem 1.85rem 0.2rem 1.85rem;

      font-size: 1rem;

      .mat-expansion-indicator {
        margin-top: -0.47rem;
      }
    }
  }*/
}

.mat-button, .mat-flat-button, .mat-icon-button, .mat-stroked-button {
  &.legend-button {
    position: absolute;
    top: 0;
    right: 1rem;

    /*border-right: 0.5rem solid $black;
    border-left: 0.5rem solid $black;*/
    line-height: 1.6rem;
    min-width: 0;
  }
}

.mat-expansion-panel-field-wrapper {
  position: relative;

  .mat-label {
    position: absolute;
    top: -0.1rem;
    left: 0.2rem;
    z-index: 2;
  }
}

/*.mat-expansion-panel-header {
  padding-left: 0 !important;
  padding-right: 0 !important;

  background-color: theme-color("primary");
}*/

.mat-form-field {
  /*min-height: 5rem;*/

  &.mat-form-field-static {
    min-height: 3rem;

    .mat-radio-group {
      display: block;
      margin-top: 0.25rem;

      .mat-radio-button {
        margin-right: 1.5rem;
      }
    }
  }
}

.mat-label {
  display: table;
  line-height: 1;
  padding-top: 0.125rem;

  /*color: $gray-500;*/
  font-size: 0.75rem;
}

/*.mat-placeholder-required {
  color: theme-color("gw-red");
}*/
.mat-stepper-horizontal,
.mat-stepper-vertical {
  background-color: transparent;
}

.mat-tab-body {
  padding: 0;
}

/*.mat-tab-group {
  &.mat-tab-group-headless {
    .mat-tab-header {
      display: none;
    }
  }
}*/

.mat-tab-group-headless.mat-tab-group {
  > .mat-tab-header {
    display: none;
  }
}

.mat-tab-label {
  min-width: 0 !important;
  padding: 0 !important;
}

// .mat-table {
//   margin: 0 0 0 0;

//   table {
//     background-color: #1a1a1a;
//     margin-bottom: 1px;


//     thead {
//       display: block;
//       margin-right: 10px;
//       margin-left: 10px;
//     }

//     tr {
//       margin: 0 0 0 0;
//     }

//     tbody {
//       overflow-y: scroll;
//       display: block;
//       height: 100px;
//       padding-left: 10px;
//       background-color: #111111;
//       @include scrollbars(10px, lightgrey, #555555);
//     }

//     td {
//       button {
//         background-color: darkorange;
//       }
//     }

//     th {
//       font-size: 1px;
//       font-weight: normal;
//       background-color: #1a1a1a;
//     }
//   }
// }
