@import 'abstracts/variables';

.sidebar-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  border-top: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  padding: 0;

  .btn {
    @include border-radius(0);

    border: 0;
    border-left: 1px solid #333333;
    margin: 0;
    padding: 1rem;

    background-color: $gray-300;
    color: #cccccc;
    font-size: $font-size-md;

    &:first-child {
      border-left: 0;
    }

    &:hover {
      @include transition(all 0.15s ease-in-out);

      background-color: darken($gray-300, 2%);
      color: $gray-light;
    }
  }
}

.sidebar-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.sidebar-header {
  padding: 1.8rem;

  background-color: $gray-000;
}

.sidebar-title {
  margin: 0;
  width: 100%;

  color: #cccccc;
  font-size: $font-size-md;
  text-align: center;
}


// sidebar
[ng-sidebar-content] {
  flex-direction: column;
  display: flex;
  height: 100%;
}

.ng-sidebar {
  /*min-width: 37rem;*/
  //background-color: $gray-900;
  background-color: $gray-400;
  /*z-index: 99999999 !important; // allow mat-select options to sit above*/
  z-index: 999 !important; // allow mat-select options to sit above
  &.navigation {
    min-width: 20rem;
  }

  .ng-sidebar-actions {
    display: table;
    min-width: 7rem;
    margin: 0.5rem auto;

    button {
      margin-left: 0.5rem;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .ng-sidebar-actions-wrapper {
    width: 100%;
  }

  .ng-sidebar-container {
    padding: 2rem;
  }

  .ng-sidebar-content {
  }

  .ng-sidebar-title {
    display: block;
    margin: 0 0 1.5rem;
    font: 500 1.5rem/2.3rem;
  }
  /*.header {
    padding: 1rem;
    background-color: $black;
    color: $white;
    text-align: center;
  }*/
}

.ng-sidebar__backdrop {
  z-index: 998 !important;

  opacity: 0.5 !important;
}

.ng-sidebar--right {
  width: 100%;
}

@media only screen and (min-width: $breakpoint-1) {
  .ng-sidebar--right {
    width: 95%;
  }
}

@media only screen and (min-width: $breakpoint-2) {
  .ng-sidebar--right {
    width: 80%;
  }
}

@media only screen and (min-width: $breakpoint-3) {
  .ng-sidebar--right {
    width: 50%;
  }

  .ng-sidebar--top {
    margin: 4rem auto 0;
    width: 37rem;
  }
}

@media only screen and (min-width: $breakpoint-4) {
  .ng-sidebar--right {
    width: 35%;
  }
}

@media only screen and (min-width: $breakpoint-5) {
  .ng-sidebar--right {
    width: 25%;
  }
}

@media only screen and (min-width: $breakpoint-6) {
}
