@import 'abstracts/mixins';

/* ==========================================================================
   Buttons
   ========================================================================== */

.btn-cancel,
.btn-save {
  i {
    @include transition(all 0.15s ease-in-out);
  }
}

.btn-cancel {
  i {
    color: darken(#ff001f, 20%);
  }

  &:hover i {
    color: $danger;
  }
}

.btn-save {
  i {
    color: darken($success, 20%);
  }

  &:hover i {
    color: $success;
  }
}


/*  Bootstrap
    ========================================================================= */

.btn {
  @include border-radius(1.21429rem);

  padding: 0.375rem 2rem;
}

.btn-lg {
  @include border-radius(1.5rem);

  padding: 0.5rem 2rem;
}


/*  Icons
    ========================================================================= */

.icon-left {
  margin-right: 0.5rem;
}
