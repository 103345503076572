/* Breakpoints
   ========================================================================== */

$breakpoint-1: 375px;
$breakpoint-2: 480px;
$breakpoint-3: 768px;
$breakpoint-4: 1024px;
$breakpoint-5: 1366px;
$breakpoint-6: 1600px;

/* Colours
   ========================================================================== */

$white: #ffffff;

$gray-light: #bbbbbb;
$gray-lighter : #F0F0F0;
$gray: #cccccc;
$gray-dark: #eeeeee;

$gray-000: #111111;
$gray-100: #1a1a1a;
$gray-200: #222222;
$gray-300: #242424;
$gray-400: #333333;
$gray-500: #444444;
$gray-600: #555555;
$gray-700: #666666;
$gray-800: #999999;
$gray-850: #9999;
$gray-900: #f8f9fa; //#cccccc

$danger: #ff001f;
$success: #28c941;
$warning: #ffbd2d;

$success-button: #298308;
$success-button-hover: #226D07;
$danger-button: #b71414;
$danger-button-hover: #991111;

$gold-bg: #8c7133;
$gold-txt: #978251;


/* Font Sizes
   ========================================================================== */
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`
$font-size-lg: $font-size-base * 1.25 !default;
$font-size-sm: $font-size-base * .875 !default;

$font-size-md: $font-size-base * 1.14285714 !default;

/* Bootstrap Overrides
   ========================================================================== */

$dark: $gray-000;


$grid-breakpoints: ( xs: 0, sm: 768px, md:1024px, lg:1366px, xl: 1600px);

$header-btn-height: 3.12rem;
