@use '@angular/material' as mat;
@import 'gwc-pallette';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

/*$my-app-primary: mat-palette($md-gwc-light);
$my-app-accent: mat-palette($md-gwc-light, 500, 900, A100);*/
$my-app-primary: mat.define-palette($md-gwc-gold, 400, 300, 50);
$my-app-accent: mat.define-palette($md-gwc-dark, 500, 400, 300);

/*$my-app-accent: mat-palette($md-gwc-dark, 500, 900, A100);*/
$my-app-warn: mat.define-palette(mat.$red-palette);

$my-app-theme: mat.define-dark-theme($my-app-primary, $my-app-accent, $my-app-warn);

.dark-theme {
    @include mat.all-legacy-component-themes($my-app-theme);
}

$my-app-primary2: mat.define-palette($md-gwc-gold, 400, 300, 50);
$my-app-accent2: mat.define-palette($md-gwc-light, 500, 400, 300);

/*$my-app-accent: mat-palette($md-gwc-dark, 500, 900, A100);*/
$my-app-warn2: mat.define-palette(mat.$red-palette);

$my-app-theme2: mat.define-light-theme($my-app-primary2, $my-app-accent2, $my-app-warn2);

.light-theme {
   @include mat.all-legacy-component-themes($my-app-theme2);
}
