@import 'abstracts/variables';

/* ==========================================================================
   Accordion
   ========================================================================== */

.accordion-header {
  color: #cccccc;
  font-size: $font-size-md;
  font-weight: 700;
}
