html,
body {
  height: 100%;
}

html {
  font-size: 0.875rem;
}

body {
  color: #cccccc;
  /*.content {
    margin: 0.625rem 0 0 0;
  }*/
  .header {
    margin: 0.625rem 0 0 0;
  }

  &::before {
    content: "0";
    display: none;
  }

  background-color: $gray-300;
  color: $gray-dark;

  @media only screen and (min-width: $breakpoint-1) {
    &::before {
      content: "1";
    }
  }

  @media only screen and (min-width: $breakpoint-2) {
    &::before {
      content: "2";
    }
  }

  @media only screen and (min-width: $breakpoint-3) {
    /*.content {
      margin: 0 0 1.25rem;
    }*/
    .header {
      margin: 1.25rem 0 0;
    }

    &::before {
      content: "3";
    }
  }

  @media only screen and (min-width: $breakpoint-4) {
    &::before {
      content: "4";
    }
  }

  @media only screen and (min-width: $breakpoint-5) {
    .content {
      margin: 0 2rem;
    }

    .header {
      margin: 1.25rem 0.9rem 0;
    }

    &::before {
      content: "5";
    }
  }

  @media only screen and (min-width: $breakpoint-6) {
    .content {
      margin: 0 4.5rem;
    }

    .header {
      margin: 1.25rem 3.45rem 0;
    }

    &::before {
      content: "6";
    }
  }
}
