/* ==========================================================================
   Auto Complete
   ========================================================================== */

.app-autocomplete {
  position: relative;

  .md-form {
    margin-bottom: 0;
  }
}

.autocomplete-filter-results {
  background-color: $gray-600;
  text-align: left;
}

.autocomplete-filter-result-container {
  position: absolute;
  top: 2.125rem;

  /*max-height: 35rem;
  overflow: auto;*/
  width: 100%;

  z-index: 999;
}

.autocomplete-item--active {
  background: $gray-900;
  color: $black;
}

.autocomplete-options {
  margin: 0;
  padding: 0;
  width: 100%;

  list-style: none;

  li {
    padding: 0.5rem;

    &:hover {
      background: $gray-800;
      color: $black;
      cursor: pointer;
    }
  }
}
