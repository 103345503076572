/*	=========================================================================
    Reset
	========================================================================= */

* {
  &:active,
  :focus {
    outline: 0 !important;
  }
}

input {
  outline: none !important;
}

/*a:not(.mat-button):not(.mat-raised-button):not(.mat-fab):not(.mat-mini-fab):not([mat-list-item]) {
  color: #3f51b5;
}*/

/*  Bootstrap
    ========================================================================= */

fieldset {
  position: relative;
  margin-bottom: 1rem;

  background-color: $gray-000;

  &.fieldset-form {
    padding: 0.9375rem 0;
  }

  &.transparent {
    background-color: transparent;

    legend {
      padding: 1rem;

      background-color: transparent;
    }
  }

  .btn-fieldset {
    position: absolute;
    top: 0;
    right: 0;

    margin: 0.875rem 2rem;
  }
}

legend {
  margin: 0;
  padding: 1rem 2rem;
  background-color: $gray-100;
  color: #cccccc;
  font-size: 16px;
  font-weight: 700;
}

// Bootstrap button
.btn:focus, .btn:active {
  box-shadow: none !important;
}

.btn-sm {
  @include border-radius(14px);

  padding: 0.25rem 1.5rem;
}

// Bootstrap button group
.btn-group {
  height: 3.12rem;

  .btn {
    @include border-radius(0);

    padding-left: 2.5rem;
    padding-right: 2.5rem;

    background-color: $gray-600;
    border-color: #555555;
    color: #cccccc;

    &.active {
      background-color: $gold-bg;
      border-color: $gold-bg;

      &:hover {
        background-color: lighten(#8c7133, 10%);
        border-color: lighten(#8c7133, 10%);
      }
    }

    &:hover {
      background-color: lighten(#8c7133, 10%);
      border-color: lighten(#8c7133, 10%);
      color: lighten(#cccccc, 10%);
    }
  }
}

// Bootstrap custom radio button
.custom-control-label::before {
  top: 0.3rem;
  width: 0.875rem;
  height: 0.875rem;
}

.custom-control-input:checked ~ .custom-control-label::before {
  border: 0;
  color: $success;
  border-color: transparent;
  background-color: $success;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: none;
}

// Bootstrap custom select
.custom-select {
  border: 0;
  margin-top: -2px; // vertical centering hack
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23bbbbbb' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px;
  background-color: transparent;
  color: #cccccc;

  option {
    padding: 11px;
    background-color: $gray-900;
    color: #333333;

    &:hover {
      background-color: $white;
    }
  }
}

.custom-button {
  border: 0;
  margin-top: -2px;
  background-color: $gray-000;
  color: #cccccc;

  &:hover {
    background-color: lighten(#8c7133, 10%);
    border-color: lighten(#8c7133, 10%);
    color: lighten(#cccccc, 10%);
  }
}

.custom-button-gold {
  border: 0;
  margin-top: -2px;
  background-color: $gold-bg !important;
  color: #cccccc  !important;

  &:hover {
    background-color: $gray-000 !important;
    border-color: $gray-000 !important;
    color: #cccccc !important;
  }
}

.custom-button-green {
  border: 0;
  margin-top: -2px;
  background-color: $success-button;
  color: #cccccc;
  margin: 5px;

  &:hover {
    background-color: $success-button-hover;
    border-color: $success-button-hover;
    color: #cccccc;
  }
}

.custom-button-red {
  border: 0;
  margin-top: -2px;
  background-color: $danger-button;
  color: #cccccc;
  margin: 5px;

  &:hover {
    background-color: $danger-button-hover;
    border-color: $danger-button-hover;
    color: #cccccc;
  }
}

// Bootstrap focus
textarea:focus,
textarea.form-control:focus,
input.form-control:focus,
input[type=text]:focus,
input[type=password]:focus,
input[type=email]:focus,
input[type=number]:focus,
[type=text].form-control:focus,
[type=password].form-control:focus,
[type=email].form-control:focus,
[type=tel].form-control:focus,
[contenteditable].form-control:focus {
  box-shadow: none;
}

// Bootstrap form control
.form-control,
.form-control-sm,
.form-control:focus,
.form-control-sm:focus {
  border: 0;
  margin-top: -2px; // vertical centering hack
  background-color: transparent;
  color: #cccccc;
}

// Bootstrap input group text
.input-group-text {
  border: 0;
  background-color: transparent;
  color: #cccccc;
}

// Bootstrap pagination
.pagination {
  float: none;

  justify-content: center;
}

.page-item.active .page-link {
  color: #fff;
  background-image: linear-gradient(to bottom, #8c7133, darken(#8c7133, 10%));
  border-color: $gold-bg;
}

.page-item.disabled .page-link {
  border: 1px solid #111111;
}

.page-link {
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
  background-image: linear-gradient(to bottom, #292929, #111111);
  color: $white;
  border: 1px solid #111111;

  &:hover {
    border: 1px solid #333333;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.5);
    background-image: linear-gradient(to bottom, #555555, #333333);
    color: $white;
  }
}

@media only screen and (min-width: $breakpoint-3) {
  .pagination {
    float: left;

    margin-left: 1rem;
    margin-right: 1rem;
  }
}

@media only screen and (min-width: $breakpoint-5) {
  .pagination {
    margin-left: 0;
    margin-right: 0;
  }
}

// Bootstrap table
.table {
  thead {
    th {
      border: 0;
    }
  }

  td,
  th {
    padding: 0.65rem 0.75rem;
  }

  td {
    border-top: 1px solid $gray-300;

    background-color: $gray-000;
    color: $gray-dark;
  }

  th {
    border-top: 0;
    background-color: $gray-400;
    color: #cccccc;
  }

  tfoot {
    td {
      background-color: transparent;
    }
  }
}

.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
  background-color: $gray-200;
}


/*  angular-6-datatable-cc
    ========================================================================= */

mfdefaultsorter a span {
  margin-left: 5px;
}


/*  Block UI
    ========================================================================= */

.block-ui-spinner, .block-ui-template {
  top: 50% !important;
  margin: -32px auto 0 !important;
}

.block-ui-wrapper {
  background: rgba(0, 0, 0, 0.50) !important;
}
