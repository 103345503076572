/* ==========================================================================
   Quill Editor
   ========================================================================== */

.quill-editor-wrapper {
  background-color: $gray-900;
}

.ql-editor {
  min-height: 14rem;

  color: #000;
}

.ql-toolbar.ql-snow {
  border: 0; 

  background: #aaa;
}
