@import 'abstracts/variables';

/* ==========================================================================
   Modals
   ========================================================================== */

.modal-container {
  background-color: $gray-400;
}

.modal-content {
  background-color: $gray-400;
  border: 0;
  border-radius: 0;
}

.modal-footer {
  padding: 0;
  border-top: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;

  .btn {
    @include border-radius(0);
    border: 0;
    border-left: 1px solid #333333;
    margin: 0;
    padding: 1.25rem;
    background-color: $gray-300;
    color: #cccccc;
    font-size: $font-size-md;

    &:first-child {
      border-left: 0;
    }

    &:hover {
      @include transition(all 0.15s ease-in-out);

      background-color: darken($gray-300, 2%);
      color: $gray-light;
    }
  }
}

.modal-header {
  border-bottom: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 1rem 1rem;

  background-color: $gold-bg;
}

.modal-message {
  padding: 1rem;
  font-size: $font-size-md;
  text-align: center;
}

.modal-subject {
  padding: 1rem;
  font-size: $font-size-lg;
  font-weight: 700;
  text-align: center;
}

.modal-title {
  width: 100%;

  color: #cccccc;
  font-size: $font-size-md;
  text-align: center;
}
