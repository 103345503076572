.datatable {
  &.table {
    .data-cell-action,
    .header-cell-action {
      border-left: 1px solid $gray-300;
      padding: 0;
      width: 1%;

      white-space: nowrap;

      a {
        display: inline-block;
        padding: 0.65rem 0.9rem;
        color: $gold-txt;
        text-decoration: none;
      }

      span.label {
        display: none;
      }
    }

    .header-cell-action {
      text-align: center;
    }

    .responsive-table {
      tr td {
        border: 0;
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
      }
    }

    .responsive-row {
      td {
        background-color: $gray-700;
      }
    }
  }

  @media only screen and (min-width: $breakpoint-3) {
    //Bootstrap override
    &.table {
      th,
      td {
        padding: 1.4rem 0.75rem;
      }

      .data-cell-action,
      .header-cell-action {
        a {
          display: inline-block;
          padding: 1.4rem;
        }

        span.label {
          display: inline;
        }
      }

      tfoot td {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
}
