@import 'compass-mixins/lib/compass/css3';

/* Custom Scrollbar
  ========================================================================= */

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  /*the draggable scrolling handle.*/
  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 25px;
  }
  /*the track (progress bar) of the scrollbar.*/
  &::-webkit-scrollbar-track {
    background: $background-color;
  }
  /* Buttons */
  &::-webkit-scrollbar-button {
    border-style: solid;
    height: $size + 2;
    width: $size + 2;
    background: $background-color;
  }
  /* Up Triangle*/
  &::-webkit-scrollbar-button:vertical:decrement {
    border-width: 0 $size/2 $size $size/2;
    border-color: transparent transparent $foreground-color transparent;
  }
  /* Down Triangle*/
  &::-webkit-scrollbar-button:vertical:increment {
    border-width: $size $size/2 0 $size/2;
    border-color: $foreground-color transparent transparent transparent;
  }
  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
