@import 'abstracts/mixins';
@import 'abstracts/variables';

label.form-field-label {
  margin: 0 1.25rem 0 0;
}

label.form-field-label {
  margin-top: -1px;
}

label.form-field-label,
span.form-field-label {
  display: none;
  color: #cccccc;
}

.header-button-inner {
  cursor: pointer;
}

.header-button-outer {
  margin-bottom: 0.625rem;

  &.disabled {
    background-color: lighten($gray-000, 3%);
    color: darken($gray-dark, 30%);
  }
}

.header-field-outer,
.header-button-outer {
  @include border-radius(1.25rem);

  display: table;
  height: 2.5rem;
  width: 100%;

  background-color: $gray-000;
  text-align: center;
}

.header-field-outer {
  margin-bottom: 0.625rem;
  padding: 0 0.5625rem;

  // Bootstrap override
  .custom-control-inline:last-child {
    margin-right: 0;
  }
}

.header-field-inner,
.header-button-inner {
  display: table-cell;
  vertical-align: middle;
}

.header-button-inner {
  cursor: pointer;
}

.header-button-label {
  margin-left: 0.5rem;
}

@media only screen and (min-width: $breakpoint-3) {
  .header-field-outer,
  .header-button-outer {
    @include border-radius(1.5625rem);

    height: 3.12rem;
    margin-bottom: 1.25rem;
  }
}

@media only screen and (min-width: $breakpoint-4) {
  label.form-field-label,
  span.form-field-label {
    display: inline;
  }
}
