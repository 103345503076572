/* ==========================================================================
   Forms
   ========================================================================== */

.form-group-static {
  position: relative;
  margin-bottom: 1.75452381rem;
  margin-top: 0.5rem;

  input {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    margin: 0.1075rem 0 0 0;
    padding: 1px 3px 3px 3px;
    background: #555555;
    color: white;
    cursor: default;
  }

  mat-select {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    margin: 0.1075rem 0 0 0;
    padding: 1px 3px 3px 3px;
    background: #555555;
    color: white;
    cursor: default;
  }

  label {
    display: table;
    margin: -1px 0 1px 0;
    padding: 0;
    line-height: 1.1;
    font-size: 0.843rem;
    float: left;
  }

  .suffix {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1.142857rem 0.3125rem 0 0;
    color: white;
  }

  select {
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.7);
    margin: 0.1075rem 0 0 0;
    padding: 1px 3px 3px 3px;
    background: #555555;
    color: white;
    cursor: default;
  }

  select:disabled {
    opacity: 1;
  }

  .label-right {
    display: table;
    margin: -1px 0 1px 0;
    padding: 0;
    line-height: 1.1;
    font-size: 11px;
    float: right;
  }

  a.label-right {
    cursor: pointer;
  }

  textarea {
    border: 1px solid rgba(255, 255, 255, 0.7);
    background: #555555;
    color: white;
    cursor: default;
  }

  button {
    margin-top: 12px;
  }
}
