@use "sass:math";

// abstracts
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/helpers';
@import 'abstracts/mixins';
@import 'abstracts/placeholders';
// bootstrap
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/buttons';
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/button-group";
@import 'bootstrap/scss/input-group';
@import 'bootstrap/scss/custom-forms';
@import 'bootstrap/scss/pagination';
@import 'bootstrap/scss/utilities';

/*@import "~bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/input-group";
@import "bootstrap/scss/custom-forms";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/jumbotron";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/media";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/print";*/

// ngx-toastr
@import 'ngx-toastr/toastr-bs4-alert';
// base
@import 'base/reset';
@import 'base/typography';
// components
@import 'components/accordion';
@import 'components/autocomplete';
@import 'components/buttons';
@import 'components/datatable';
@import 'components/forms';
@import 'components/modals';
@import 'components/ng-sidebar';
@import 'components/quill-editor';
// layout
@import 'layout/header';
@import 'layout/grid';
@import 'layout/main';
// themes
/*@import 'themes/gwc';*/
// shame
@import 'shame';


.mat-dialog-container {
    background-color: #111111;
    .container-fluid {
        background-color: #111111;
    }

    .mat-dialog-actions {
        .btn {
            background-color: #111111;
            border-radius: 25px !important;
        }
    }
}

.link {
    color:#0A8DE8 !important;
    cursor: pointer;
}

.darkLink
{
    color:#054b7e !important;
    cursor: pointer;
}
